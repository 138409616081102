/*@import "base.css";*/

#container {
    min-width: 1000px;
}

.banner-module {
    position: relative;
    width: 100%;
    z-index: 1;
}
.swiper-banner {
    height: 525px;
}
.swiper-banner .swiper-slide {
    position: relative;
}
.swiper-banner .bg-img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
}
.swiper-banner .banner-content {
    position: relative;
    z-index: 1;
    width: 960px;
    margin: 0 auto;
    padding-top: 156px;
    height: 525px;
}
.swiper-banner .banner-content .title {
    font-size: 40px;
    line-height: 50px;
    font-weight: bolder;
}
.swiper-banner .banner-content .desc-wrap {
    margin-top: 20px;
    width: 488px;
    font-size: 16px;
    line-height: 26px;
}
.swiper-banner .banner-content .btn-banner {
    display: block;
    margin-top: 20px;
    width: 136px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background: #0079FF;
    border-radius: 2px;
    font-size: 16px;
    color: #fff;
    border: none;
    cursor: pointer;
}
.swiper-banner .banner-content .btn-banner:hover {
    background: #008CFF;
}
.swiper-banner .swiper-pagination-banner {
    position: absolute;
    width: 100%;
    height: 52px;
    display: flex;
    justify-content: center;
    left: 0;
    bottom: 0!important;
    z-index: 3;
    background: rgba(255, 255, 255, .5);
}
.swiper-banner .swiper-pagination-bullet {
    width: 320px;
    height: 52px;
    margin: 0!important;
    line-height: 52px;
    color: #000;
    font-size: 14px;
    border-radius: 0;
    background: transparent!important;
    opacity: 1;
    text-align: center;
}
.swiper-banner .swiper-pagination-bullet-active {
    color: #0079FF;
    background: linear-gradient(180deg, #FFFFFF 30%, rgba(255,255,255,0) 100%)!important;
}

.module-content {
    width: 1000px;
    margin: 0 auto;
    padding: 0 20px;
}
.product-1 {
    height: 379px;
    padding: 48px 20px;
    background: #fff;
}
.product-2 {
    height: 386px;
    padding: 48px 20px 50px;
    background: #F7F9FC;
}

.module-product h2 {
    font-size: 32px;
    line-height: 40px;
    text-align: center;
}
.product-content-items {
    position: relative;
    overflow: hidden;
}
.product-content-items > .bg {
    position: absolute;
    width: 490px;
    height: 155px;
    right: 0;
    bottom: 0;
}
.product-content-items .product-item {
    float: left;
    width: 320px;
    height: 155px;
    padding-left: 24px;
}
.product-content-items .product-type {
    position: relative;
    height: 62px;
    line-height: 62px;
    font-size: 16px;
    color: #000;
    font-weight: bolder;
    margin-bottom: 8px;
}
.product-content-items .product-type:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 160px;

    border-top: 1px #E2E2E2 solid;
}
.product-content-items .product-list {
    height: 32px;
}
.product-content-items .product-list > a {
    font-size: 14px;
    color: #000000;
    vertical-align: middle;
    cursor: pointer;
}
.product-content-items .product-list > a:hover {
    color: #0079FF;
}

.swiper-product {
    position: relative;
    height: 211px;
    margin: 38px auto 0;
    padding-top: 56px!important;
    box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.05);
}
.swiper-product .swiper-wrapper {
    height: 155px;

}
.swiper-pagination-product {
    display: flex;
    position: absolute;
    width: 100%;
    height: 56px;
    left: 0;
    top: 0;
}
.swiper-product .swiper-pagination-bullet {
    display: flex;
    align-items: center;
    width: 320px;
    height: 56px;
    padding-left: 24px;
    border-radius: 0!important;
    margin: 0!important;
    background: #F2F5FA!important;
    font-size: 20px;
    color: #000!important;
    opacity: 1!important;
    font-weight: bold;
}
.swiper-product .swiper-pagination-bullet > .icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
}
.swiper-product .swiper-pagination-bullet-active {
    background: #fff!important;
    color: #0079FF!important;
}
.swiper-pagination-product-0 {
    background: url(../imgs/icon-product-1.png) center / cover no-repeat;
}
.swiper-pagination-product-1 {
    background: url(../imgs/icon-product-2.png) center / cover no-repeat;
}
.swiper-pagination-product-2 {
    background: url(../imgs/icon-product-3.png) center / cover no-repeat;
}

.module-resolve {
    display: flex;
    height: 216px;
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.05);
}
.resolve-item {
    position: relative;
    flex: 0 0 320px;
    padding: 32px 24px;
    background: #fff;
}
.resolve-item.line:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 152px;
    background: #e2e2e2;
    right: 0;
    top: 32px;
}
.resolve-item > img {
    position: absolute;
    width: 130px;
    height: 130px;
    left: 166px;
    top: 50px;
    object-fit: contain;
}
.resolve-msg {
    position: relative;
    z-index: 2;
}
.resolve-item .resolve-msg .title {
    height: 48px;
    font-size: 0;
    line-height: 1;
}
.resolve-item .resolve-msg .title .count{
    float: left;
    font-size: 48px;
    font-weight: 700;
}
.resolve-item .resolve-msg .title .text{
    float: left;
    margin-left: 8px;
    padding-top: 22px;
    font-size: 20px;
    vertical-align: bottom;
    font-weight: 500;
}
.resolve-links {
    margin-top: 16px;
}
.resolve-links .link {
    margin-bottom: 8px;
    line-height: 24px;
}
.resolve-links .link.disabled > a {
    color: rgba(0, 0, 0, .3);
}
.resolve-links .link a {
    font-size: 14px;
    color: #000;
}
.resolve-links .link a:hover {
    color: #0079FF;
}
.resolve-links .link.disabled > a:hover {
    color: rgba(0, 0, 0, .3);
    cursor: not-allowed;
}

.product-3 {
    height: 276px;
    padding: 48px 0;
    background: #fff;
}
.product-3 .cooperate-desc {
    font-size: 16px;
    color: #000;
    font-weight: bold;
    margin-top: 12px;
    text-align: center;
}
.product-3 .cooperate-company {
    display: block;
    margin-top: 32px;
    width: 960px;
}

.product-4 {
    padding: 48px 0;
    background: #F7F9FC;
}
.company-intro {
    margin-top: 32px;
    font-size: 14px;
    color: #212B36;
    line-height: 24px;
    text-align: center;
}
.btn-subs {
    display: block;
    width: 168px;
    height: 40px;
    margin: 48px auto 0;
}
.btn-subs:hover {
    background: #008CFF;
}
